
// import * as bootstrap from 'bootstrap'
//require('bootstrap-icons/font/bootstrap-icons.css');


function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
    }
}

// Elements needed
const search_box = document.getElementById("search-bar")
const hidden_geo_id = document.getElementById("hidden-geo-id")
const datalist = document.getElementById("matched")

async function handleChange(e) {

    const query = e.target.value
    if(!query.length || query.length < 2){
        datalist.style.display = 'none';
        removeAllChildNodes(datalist)
        return;
    }
    const input = `/api/geo?q=${encodeURIComponent(query)}`;
    const res = await fetch(input)
    const body = await res.json()
    removeAllChildNodes(datalist)
    for (const {id, name, parent_names} of body) {
        const node = document.createElement("div");
        node.className = "text-suggestion";
        let dropdownText = name;
        if (parent_names != null && parent_names.length > 0) {
            for (var i = parent_names.length-1; i>=0; i--) {
                dropdownText = dropdownText.concat(', ').concat(parent_names[i])
            }
        }
        const textnode = document.createTextNode(dropdownText);
        node.appendChild(textnode);
        node.addEventListener("click", (e) => {
            search_box.value = dropdownText;
            hidden_geo_id.value = id;
            removeAllChildNodes(datalist)
        })
        datalist.style.display = 'flex'
        datalist.appendChild(node)
    }
    window.onclick = function(e){
        if (!e.target.matches('.search-results__options')){
            datalist.style.display = 'none'
        }
    }
}
datalist.style.display = 'none';
search_box.addEventListener('keyup', handleChange)

